/*global $, jQuery, alert, console*/

"use strict";
var isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


$(document).ready(function () {
    controlTopMenu();

    $('.sidenav').sidenav();
    $('.modal').modal();
    // $('.tooltipped').tooltip();
    $('.collapsible').collapsible();
    // $('.dropdown-link_material').dropdown();
    $('.tabs').tabs();
    $('.dropdown-trigger').dropdown();
    $('.lazy').Lazy();

    $('.top-menu .menu-item-has-children').on('click', function () {
        $(this).find('.sub-menu').toggle();
    })
    $('.sidenav .menu-item-has-children').on('click', function () {
        $(this).toggleClass('active-menu');
        $(this).find('.sub-menu').toggle();
    })

    $('.copy-link').on('click', function (e) {
        e.preventDefault();
        var copyText = window.location.href;

        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(copyText);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = copyText;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy')
            } catch (err) {
                console.error('Unable to copy to clipboard', err)
            }
            document.body.removeChild(textArea)
        }
    })


    let perPage = 6;
    // $('.page-template-redeem ul.s-packs__tabs1 li').each(function(i,e){
    //     // let items1 = $("#suppliesTab .s-packs__tab-result-block");
    //     let items = $("#tab"+i+" .s-packs__tab-result-block");
    // })

    function custompagination(elements, i){
        elements.slice(perPage).hide();
        $('#light-pagination'+i).pagination({
            items: elements.length,
            itemsOnPage: perPage,
            onPageClick: function(pageNumber) {
                let showFrom = perPage * (pageNumber - 1);
                let showTo = showFrom + perPage;

                elements.hide().slice(showFrom, showTo).show();
            }
        });
    }

    let items1 = $("#tab1 .s-packs__tab-result-block");
    let items2 = $("#tab2 .s-packs__tab-result-block");
    let items3 = $("#tab3 .s-packs__tab-result-block");
    let items4 = $("#tab4 .s-packs__tab-result-block");
    let items5 = $("#tab5 .s-packs__tab-result-block");
    let items6 = $("#tab6 .s-packs__tab-result-block");
    custompagination(items1, 1);
    custompagination(items2, 2);
    custompagination(items3, 3);
    custompagination(items4, 4);
    custompagination(items5, 5);
    custompagination(items6, 6);



    $('.s-reviews__slider').slick({
        dots: false,
        rtl: $('html').attr('dir') == 'rtl' ? true : false,
        // infinite: true,
        // speed: 300,
        // slidesToShow: 1,
        arrows: true,
        variableWidth: true,
        adaptiveHeight: false,
        prevArrow: '<div class="prev-btn"><img src="/wp-content/themes/muallemi/assets/img/slider-arrow-left.svg" alt=""></div>',
        nextArrow: '<div class="next-btn"><img src="/wp-content/themes/muallemi/assets/img/slider-arrow-right.svg" alt=""></div>'
    });

    tabConttroller();
    detectSeenElements();

    $('.animated-scroll').on('click', function (e) {
        e.preventDefault();
        var linkHref = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(linkHref).offset().top - 120
        }, 500);
    });

    function controlTopMenu() {
        var scrollHeight = $(window).scrollTop();

        if (scrollHeight > 0) {
            $('.top-menu').addClass('not-top');
        } else {
            $('.top-menu').removeClass('not-top');
        }
        $(window).scroll(function () {
            scrollHeight = $(window).scrollTop();

            if (scrollHeight > 0) {
                $('.top-menu').addClass('not-top');
            } else {
                $('.top-menu').removeClass('not-top');
            }
        });
    }

    function detectSeenElements() {
        var sections = $('section');

        $(window).scroll(function () {
            sections.each(function (i,e) {
                var top_of_element = $(this).offset().top + $(window).innerHeight() / 2;
                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                var top_of_screen = $(window).scrollTop();

                if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                    $(this).addClass('seen')
                } else {
                    // the element is not visible, do something else
                }
            })
        });
    }

    function tabConttroller() {
        $('.tab-btn').on('click', function (e) {
            e.preventDefault();
            let curIndex = $(this).attr('data-index');

            $(this).closest('.s-tab__btns-wrap').find('.tab-btn').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curIndex) {
                    $(this).addClass('active')
                }
            })

            $(this).closest('.s-tab__content').find('.s-tab__img').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curIndex) {
                    $(this).addClass('active')
                }
            })

            $(this).closest('.s-tab__content').find('.s-tab__tab-content').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curIndex) {
                    $(this).addClass('active')
                }
            })
        })
    }

    $('.s-slider__slider').slick({
        arrows: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        speed: 1000,
        prevArrow: '<div class="prev-btn"></div>',
        nextArrow: '<div class="next-btn"></div>'
    });
    $('.s-slider__slider .slick-slide').on('click', function () {
        var slideIndex = $(this).attr('data-slick-index')
        $('.s-slider__slider').slick('slickGoTo', slideIndex);
    });

    $(window).resize(function () {
        addInlineSvg();
    });

    function addInlineSvg() {
        var mySVGsToInject = document.querySelectorAll('img.image_svg');
        SVGInjector(mySVGsToInject);
    }

    addInlineSvg();

    //blog page
    $(document).ready(function () {
        $('.page-template-blog .click-on-start').click();
    });

    $('.s-posts__tabs-wrap li a').on('click', function (e) {
        e.preventDefault();
        let tag_id = jQuery(this).attr("tag-id")
        let nonce = jQuery(this).attr("data-nonce")

        jQuery.ajax({
            type: "post",
            dataType: "json",
            url: '/wp-admin/admin-ajax.php',
            data: {action: "get_posts_by_tag_id", tag_id: tag_id, nonce: nonce},
            success: function (response) {
                if (response.type == "success") {
                    jQuery(".s-posts__tab-result-blocks-wrap").html(response.content)
                    jQuery(".pagination").html(response.pagination)
                } else {
                    alert("Your vote could not be added")
                }
            }
        })
    })

    $('.page-template-blog .pagination').on('click', 'li span', function (e) {
        e.preventDefault();
        let tag_id = jQuery(this).attr("tag-id")
        let paged = jQuery(this).attr("paged")
        let nonce = jQuery(this).attr("data-nonce")

        jQuery.ajax({
            type: "post",
            dataType: "json",
            url: '/wp-admin/admin-ajax.php',
            data: {action: "get_posts_by_tag_id", tag_id: tag_id, paged: paged, nonce: nonce},
            success: function (response) {
                if (response.type == "success") {
                    jQuery(".s-posts__tab-result-blocks-wrap").html(response.content)
                    jQuery(".pagination").html(response.pagination)
                }
            }
        })
    })

    //demo page
    if ($('.wpforms-form .booking_date_select').length) {
        function get_available_dates(date = '') {
            jQuery.ajax({
                type: "post",
                dataType: "json",
                url: '/wp-admin/admin-ajax.php',
                data: {action: "get_available_dates", date: date},
                success: function (response) {
                    if (response.type == "success") {
                        $('.wpforms-form .booking_date_select .wpforms-field-date-time-date-month').empty().append(response.html.options_m);
                        $('.wpforms-form .booking_date_select .wpforms-field-date-time-date-day').empty().append(response.html.options_d);
                        $('.wpforms-form .booking_date_select .wpforms-field-date-time-date-year').empty().append(response.html.options_y);
                        $('.booking_time_select input').val('').click();
                        $('body').click();
                        $('.ui-timepicker-list').empty().append(response.html.timepicker1);
                    }
                }
            })
        }

        get_available_dates();

        $('.wpforms-form .booking_date_select .wpforms-field-date-time-date-month').on('change', function () {
            let y = $('.wpforms-form .booking_date_select .wpforms-field-date-time-date-year').val();
            let m = $('.wpforms-form .booking_date_select .wpforms-field-date-time-date-month').val();
            // let d = $('.wpforms-form .booking_date_select .wpforms-field-date-time-date-day').val();
            get_available_dates(y + "-" + m + "-" + 1);
        });

        $('.wpforms-form .booking_date_select .wpforms-field-date-time-date-day').on('change', function () {
            let y = $('.wpforms-form .booking_date_select .wpforms-field-date-time-date-year').val();
            let m = $('.wpforms-form .booking_date_select .wpforms-field-date-time-date-month').val();
            let d = $('.wpforms-form .booking_date_select .wpforms-field-date-time-date-day').val();
            get_available_dates(y + "-" + m + "-" + d);
        });

        $('body').on('click', '.ui-timepicker-list li', function () {
            $('.booking_time_select input').val($(this).text());
        });
    }
});